import "@/bootstrap";
import "cropperjs/dist/cropper.css";
import "../css/app.css";
import "../css/custom-filepond.css";
import "../css/tooltip.scss";

import DesignSystem from "@/Layouts/PrimeVue";
import { pinia } from "@Library";
import BugsnagPerformance from "@bugsnag/browser-performance";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import { VanillaComponents, defineConfiguration } from "@flavorly/vanilla-components";
import { createInertiaApp } from "@inertiajs/vue3";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import PrimeVue from "primevue/config";
import { createApp, h, watch } from "vue";
import { ZiggyVue } from "ziggy-js";
import "ag-grid-enterprise";
import { LicenseManager } from "ag-grid-enterprise";

import AuthenticatedLayout from "@/Layouts/AuthenticatedLayout.vue";
import BoothLayout from "@/Layouts/BoothLayout.vue";
import EventsLayout from "@/Layouts/EventsLayout.vue";
import GuestLayout from "@/Layouts/GuestLayout.vue";
import OneLayout from "@/Layouts/Registration/Steps/OneLayout.vue";
import ThreeLayout from "@/Layouts/Registration/Steps/ThreeLayout.vue";
import TwoLayout from "@/Layouts/Registration/Steps/TwoLayout.vue";
import ShowLayout from "@/Layouts/ShowLayout.vue";
import directives from "./Directives";

import { install as onlineUserStore } from "@Stores/onlineUsersStore";
// import { tour, onlineUsers } from "@Plugins";
// import { onlineUsers } from "@Plugins";
LicenseManager.setLicenseKey(
  "Using_this_{AG_Grid}_Enterprise_key_{AG-064507}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Mid-States}_is_granted_a_{Multiple_Applications}_Developer_License_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{2_August_2025}____[v3]_[01]_MTc1NDA4OTIwMDAwMA==021be37c240e81176ba3721cdde560bd"
);
const appName = window.document.getElementsByTagName("title")[0]?.innerText || "MidStates";

const store = pinia.createPinia();

if (localStorage.getItem("pinia")) {
  store.state.value = JSON.parse(localStorage.getItem("pinia"));
}

watch(
  store.state,
  (state) => {
    localStorage.setItem("pinia", JSON.stringify(state));
  },
  { deep: true }
);

import moment from "moment-timezone";

moment.tz.setDefault("America/Chicago");

createInertiaApp({
  title: (title) => `${title} - ${appName}`,
  resolve: (name) => {
    const userType = store.state.value.user?.type;
    const page = resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob("./Pages/**/*.vue"));
    page.then((module) => {
      if (name.startsWith("Auth/")) {
        module.default.layout = GuestLayout;
      } else if (name.startsWith("Events/Event/Registration/Steps/One")) {
        module.default.layout = (h, page) => h(AuthenticatedLayout, () => h(OneLayout, () => page));
      } else if (name.startsWith("Events/Event/Registration/Steps/Two")) {
        module.default.layout = (h, page) => h(AuthenticatedLayout, () => h(TwoLayout, () => page));
      } else if (name.startsWith("Events/Event/Registration/Steps/Three")) {
        module.default.layout = (h, page) => h(AuthenticatedLayout, () => h(ThreeLayout, () => page));
      } else if (name.startsWith("Events/Event/Booth/")) {
        module.default.layout = (h, page) => h(AuthenticatedLayout, () => h(BoothLayout, () => page));
      } else if (name.startsWith("Events/Event/Show/")) {
        module.default.layout = (h, page) => h(AuthenticatedLayout, () => h(ShowLayout, () => page));
      } else if (name.startsWith("Events/Event/") && !["rep", "vendor"].includes(userType) && !name.startsWith("Events/Event/Summit/")) {
        module.default.layout = (h, page) => h(AuthenticatedLayout, () => h(EventsLayout, () => page));
      } else {
        module.default.layout = module.default.layout || AuthenticatedLayout;
      }
    });
    return page;
  },
  setup({ el, App, props, plugin }) {
    const app2 = createApp({ render: () => h(App, props) });
    app2
      .use(plugin)
      .use(ZiggyVue)
      .use(store)
      .use(
        VanillaComponents,
        defineConfiguration({
          RichSelect: {
            classes: {
              trigger:
                "bg-white dark:bg-slate-900 border-0 ring-1 ring-inset focus:ring-inset focus:ring-2 ring-slate-300 focus:ring-primary-600 dark:ring-white/20 dark:focus:ring-primary-600 focus-visible:ring-primary-600 relative flex items-center justify-between w-full focus:z-10 cursor-pointer sm:text-sm transition duration-100 ease-in-out shadow-sm disabled:opacity-50 disabled:cursor-not-allowed px-4 py-2 rounded-md",
              dropdown:
                "z-50 min-w-max overflow-hidden bg-white dark:bg-slate-900 border border-1 border-slate-300 dark:border-slate-700 rounded-md shadow-lg",
              selectButtonPlaceholder: "block text-slate-500/90 truncate",
            },
          },
          Input: {
            fixedClasses: {
              input:
                "text-sm appearance-none w-full focus:outline-none shadow-sm disabled:opacity-50 disabled:cursor-not-allowed border-0 rounded-md",
            },
            classes: {
              input:
                "border-0 text-slate-700 dark:text-white placeholder-slate-500/60 bg-white dark:bg-slate-900 ring-1 ring-inset focus:ring-inset focus:ring-2 ring-slate-300 focus:ring-primary-600 dark:ring-white/20 dark:focus:ring-primary-600 focus-visible:ring-primary-600 px-4 py-2 !autofill:bg-white !dark:autofill:bg-slate-900",
            },
          },
          Button: {
            classes: {
              button:
                "text-sm font-semibold whitespace-nowrap focus-visible:outline focus-visible:outline-2 shadow-sm focus-visible:outline-offset-2 focus-visible:outline-primary-500 text-slate-700 focus-visible:text-slate-600 dark:text-white dark:hover:text-white dark:focus:text-white bg-white dark:bg-slate-800 border border-slate-300 dark:border-slate-600 dark:focus:border-primary-600",
            },
            variants: {
              primary: {
                classes: {
                  button:
                    "text-sm font-semibold whitespace-nowrap focus-visible:outline shadow-sm focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500 text-white focus:text-white bg-primary-500 hover:bg-primary-600",
                },
              },
            },
          },
          TagsInput: {
            classes: {
              tag: "rounded shadow-sm flex items-center cursor-pointer bg-primary-100 m-0.5 h-6 max-w-full text-primary-700 white-space-no text-sm font-medium focus:outline-none focus:ring-2 focus:ring-opacity-50 focus:ring-primary-500 transition duration-100 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed",
              trigger:
                "bg-white border-0 ring-1 ring-inset focus:ring-inset focus:ring-2 ring-slate-300 focus:ring-primary-600 focus-visible:ring-primary-600 text-sm transition duration-100 ease-in-out shadow-sm disabled:opacity-50 disabled:cursor-not-allowed rounded-md px-2 py-2.5 relative flex items-center w-full focus:z-10 cursor-pointer max-w-full w-full",
            },
          },
        })
      )
      .use(PrimeVue, {
        unstyled: true,
        pt: DesignSystem,
      })
      // .use(tour)
      .use(onlineUserStore)
      .use(VueGoogleMaps, {
        load: {
          // key: 'AIzaSyCoCFp2CqmdNZlvvABs9HJ_6ae8zG9EwFc', // PROD
          key: "AIzaSyDPgA4FSwcack9bUfvWx-aMmDZhDdlOhYU", // DEV
          libraries: "places",
        },
        installComponents: true,
      });
    directives(app2);
    if (import.meta.env.VITE_BUGSNAG_API_KEY) {
      Bugsnag.start({
        apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
        plugins: [new BugsnagPluginVue()],
        releaseStage: process.env.NODE_ENV,
        onError: (event) => {
          event.addMetadata("user", props.initialPage.props.auth.user);
        },
      });

      const bugsnagVue = Bugsnag.getPlugin("vue");
      app2.use(bugsnagVue);
      BugsnagPerformance.start(import.meta.env.VITE_BUGSNAG_API_KEY);
    }
    app2.mount(el);
  },
  progress: {
    color: "#ef4444",
  },
});
